.Backdrop {
    position: fixed;
    inset: 0;
    background-color: var(--color-background-backdrop);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-index-backdrop);
}

.Dialog {
    position: relative;
    width: fit-content;
    height: fit-content;
    min-width: 80vw;
    min-height: 20vh;
    max-width: 100vw;
    max-height: 80vh;
    overflow: auto;
    color: var(--color-text-primary);
    background-color: var(--color-background-popup);
    border-radius: var(--radius-md);
    box-shadow: var(--shadow-md);
    display: flex;
    flex-direction: column;
    padding: 0;

    > * {
        padding: 1rem;
    }

    header {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-md);
        position: sticky;
        top: 0;
        z-index: var(--z-index-sticky);
        background-color: var(--color-background-popup);
    }

    main:empty {
        display: none;
    }

    footer {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
}
