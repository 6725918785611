@import (once) "colors/palette.less";

.color-scheme-light({
    --shadow-channel: var(--palette-neutral-900);
    --shadow-opacity: 10%;
});

.color-scheme-dark({
    --shadow-channel: var(--palette-neutral-700);
    --shadow-opacity: 50%;
});

// Shadows
:root {
    --shadow-ring: 0 0 var(--palette-common-black);
    --shadow-color: color-mix(in srgb, var(--shadow-channel) var(--shadow-opacity), transparent);
    --shadow-xs: var(--shadow-ring), 0px 1px 2px 0px var(--shadow-color);
    --shadow-sm: var(--shadow-ring), 0px 1px 2px 0px var(--shadow-color), 0px 2px 4px 0px var(--shadow-color);
    --shadow-md: var(--shadow-ring), 0px 2px 8px -2px var(--shadow-color), 0px 6px 12px -2px var(--shadow-color);
    --shadow-lg: var(--shadow-ring), 0px 2px 8px -2px var(--shadow-color), 0px 12px 16px -4px var(--shadow-color);
    --shadow-xl: var(--shadow-ring), 0px 2px 8px -2px var(--shadow-color), 0px 20px 24px -4px var(--shadow-color);
}
