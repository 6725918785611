@import (once) "palette.less";

// Warning colors
.color-scheme-light({
    --color-warning-plain: var(--palette-warning-500);
    --color-warning-plain-hover-bg: var(--palette-warning-100);
    --color-warning-plain-active-bg: var(--palette-warning-200);
    --color-warning-plain-disabled: var(--palette-neutral-400);
    --color-warning-outlined: var(--palette-warning-500);
    --color-warning-outlined-border: var(--palette-warning-300);
    --color-warning-outlined-hover-bg: var(--palette-warning-100);
    --color-warning-outlined-hover-border: var(--palette-warning-300);
    --color-warning-outlined-active-bg: var(--palette-warning-200);
    --color-warning-outlined-disabled: var(--palette-neutral-400);
    --color-warning-outlined-disabled-border: var(--palette-neutral-200);
    --color-warning-soft: var(--palette-warning-700);
    --color-warning-soft-bg: var(--palette-warning-100);
    --color-warning-soft-hover-bg: var(--palette-warning-200);
    --color-warning-soft-active: var(--palette-warning-800);
    --color-warning-soft-active-bg: var(--palette-warning-300);
    --color-warning-soft-disabled: var(--palette-neutral-400);
    --color-warning-soft-disabled-bg: var(--palette-neutral-50);
    --color-warning-solid: var(--palette-common-white);
    --color-warning-solid-bg: var(--palette-warning-500);
    --color-warning-solid-hover-bg: var(--palette-warning-600);
    --color-warning-solid-active-bg: var(--palette-warning-700);
    --color-warning-solid-disabled: var(--palette-neutral-400);
    --color-warning-solid-disabled-bg: var(--palette-neutral-100);
});

.color-scheme-dark({
    --color-warning-plain: var(--palette-warning-300);
    --color-warning-plain-hover-bg: var(--palette-warning-800);
    --color-warning-plain-active-bg: var(--palette-warning-700);
    --color-warning-plain-disabled: var(--palette-neutral-500);
    --color-warning-outlined: var(--palette-warning-200);
    --color-warning-outlined-border: var(--palette-warning-700);
    --color-warning-outlined-hover-bg: var(--palette-warning-800);
    --color-warning-outlined-active-bg: var(--palette-warning-700);
    --color-warning-outlined-disabled: var(--palette-neutral-500);
    --color-warning-outlined-disabled-border: var(--palette-neutral-800);
    --color-warning-soft: var(--palette-warning-200);
    --color-warning-soft-bg: var(--palette-warning-800);
    --color-warning-soft-hover-bg: var(--palette-warning-700);
    --color-warning-soft-active: var(--palette-warning-100);
    --color-warning-soft-active-bg: var(--palette-warning-600);
    --color-warning-soft-disabled: var(--palette-neutral-500);
    --color-warning-soft-disabled-bg: var(--palette-neutral-800);
    --color-warning-solid: var(--palette-common-white);
    --color-warning-solid-bg: var(--palette-warning-500);
    --color-warning-solid-hover-bg: var(--palette-warning-600);
    --color-warning-solid-active-bg: var(--palette-warning-700);
    --color-warning-solid-disabled: var(--palette-neutral-500);
    --color-warning-solid-disabled-bg: var(--palette-neutral-800);
});
