@import (once) "palette.less";

// Neutral colors
.color-scheme-light({
    --color-neutral-plain: var(--palette-neutral-700);
    --color-neutral-plain-hover: var(--palette-neutral-900);
    --color-neutral-plain-hover-bg: var(--palette-neutral-100);
    --color-neutral-plain-active-bg: var(--palette-neutral-200);
    --color-neutral-plain-disabled: var(--palette-neutral-400);
    --color-neutral-outlined: var(--palette-neutral-700);
    --color-neutral-outlined-border: var(--palette-neutral-300);
    --color-neutral-outlined-hover: var(--palette-neutral-900);
    --color-neutral-outlined-hover-bg: var(--palette-neutral-100);
    --color-neutral-outlined-hover-border: var(--palette-neutral-300);
    --color-neutral-outlined-active-bg: var(--palette-neutral-200);
    --color-neutral-outlined-disabled: var(--palette-neutral-400);
    --color-neutral-outlined-disabled-border: var(--palette-neutral-200);
    --color-neutral-soft: var(--palette-neutral-700);
    --color-neutral-soft-bg: var(--palette-neutral-100);
    --color-neutral-soft-hover: var(--palette-neutral-900);
    --color-neutral-soft-hover-bg: var(--palette-neutral-200);
    --color-neutral-soft-active: var(--palette-neutral-800);
    --color-neutral-soft-active-bg: var(--palette-neutral-300);
    --color-neutral-soft-disabled: var(--palette-neutral-400);
    --color-neutral-soft-disabled-bg: var(--palette-neutral-50);
    --color-neutral-solid: var(--palette-common-white);
    --color-neutral-solid-bg: var(--palette-neutral-500);
    --color-neutral-solid-hover-bg: var(--palette-neutral-600);
    --color-neutral-solid-active-bg: var(--palette-neutral-700);
    --color-neutral-solid-disabled: var(--palette-neutral-400);
    --color-neutral-solid-disabled-bg: var(--palette-neutral-100);
});

.color-scheme-dark({
    --color-neutral-plain: var(--palette-neutral-300);
    --color-neutral-plain-hover: var(--palette-neutral-300);
    --color-neutral-plain-hover-bg: var(--palette-neutral-800);
    --color-neutral-plain-active-bg: var(--palette-neutral-700);
    --color-neutral-plain-disabled: var(--palette-neutral-500);
    --color-neutral-outlined: var(--palette-neutral-200);
    --color-neutral-outlined-border: var(--palette-neutral-700);
    --color-neutral-outlined-hover-bg: var(--palette-neutral-800);
    --color-neutral-outlined-active-bg: var(--palette-neutral-700);
    --color-neutral-outlined-disabled: var(--palette-neutral-500);
    --color-neutral-outlined-disabled-border: var(--palette-neutral-800);
    --color-neutral-soft: var(--palette-neutral-200);
    --color-neutral-soft-bg: var(--palette-neutral-800);
    --color-neutral-soft-hover-bg: var(--palette-neutral-700);
    --color-neutral-soft-active: var(--palette-neutral-100);
    --color-neutral-soft-active-bg: var(--palette-neutral-600);
    --color-neutral-soft-disabled: var(--palette-neutral-500);
    --color-neutral-soft-disabled-bg: var(--palette-neutral-800);
    --color-neutral-solid: var(--palette-common-white);
    --color-neutral-solid-bg: var(--palette-neutral-500);
    --color-neutral-solid-hover-bg: var(--palette-neutral-600);
    --color-neutral-solid-active-bg: var(--palette-neutral-700);
    --color-neutral-solid-disabled: var(--palette-neutral-500);
    --color-neutral-solid-disabled-bg: var(--palette-neutral-800);
});
