@import (once) "palette.less";

// Primary colors
.color-scheme-light({
    --color-primary-plain: var(--palette-primary-500);
    --color-primary-plain-hover-bg: var(--palette-primary-100);
    --color-primary-plain-active-bg: var(--palette-primary-200);
    --color-primary-plain-disabled: var(--palette-neutral-400);
    --color-primary-outlined: var(--palette-primary-500);
    --color-primary-outlined-border: var(--palette-primary-300);
    --color-primary-outlined-hover-bg: var(--palette-primary-100);
    --color-primary-outlined-hover-border: var(--palette-primary-300);
    --color-primary-outlined-active-bg: var(--palette-primary-200);
    --color-primary-outlined-disabled: var(--palette-neutral-400);
    --color-primary-outlined-disabled-border: var(--palette-neutral-200);
    --color-primary-soft: var(--palette-primary-700);
    --color-primary-soft-bg: var(--palette-primary-100);
    --color-primary-soft-hover-bg: var(--palette-primary-200);
    --color-primary-soft-active: var(--palette-primary-800);
    --color-primary-soft-active-bg: var(--palette-primary-300);
    --color-primary-soft-disabled: var(--palette-neutral-400);
    --color-primary-soft-disabled-bg: var(--palette-neutral-50);
    --color-primary-solid: var(--palette-common-white);
    --color-primary-solid-bg: var(--palette-primary-500);
    --color-primary-solid-hover-bg: var(--palette-primary-600);
    --color-primary-solid-active-bg: var(--palette-primary-700);
    --color-primary-solid-disabled: var(--palette-neutral-400);
    --color-primary-solid-disabled-bg: var(--palette-neutral-100);
});

.color-scheme-dark({
    --color-primary-plain: var(--palette-primary-300);
    --color-primary-plain-hover-bg: var(--palette-primary-800);
    --color-primary-plain-active-bg: var(--palette-primary-700);
    --color-primary-plain-disabled: var(--palette-neutral-500);
    --color-primary-outlined: var(--palette-primary-200);
    --color-primary-outlined-border: var(--palette-primary-700);
    --color-primary-outlined-hover-bg: var(--palette-primary-800);
    --color-primary-outlined-active-bg: var(--palette-primary-700);
    --color-primary-outlined-disabled: var(--palette-neutral-500);
    --color-primary-outlined-disabled-border: var(--palette-neutral-800);
    --color-primary-soft: var(--palette-primary-200);
    --color-primary-soft-bg: var(--palette-primary-800);
    --color-primary-soft-hover-bg: var(--palette-primary-700);
    --color-primary-soft-active: var(--palette-primary-100);
    --color-primary-soft-active-bg: var(--palette-primary-600);
    --color-primary-soft-disabled: var(--palette-neutral-500);
    --color-primary-soft-disabled-bg: var(--palette-neutral-800);
    --color-primary-solid: var(--palette-common-white);
    --color-primary-solid-bg: var(--palette-primary-500);
    --color-primary-solid-hover-bg: var(--palette-primary-600);
    --color-primary-solid-active-bg: var(--palette-primary-700);
    --color-primary-solid-disabled: var(--palette-neutral-500);
    --color-primary-solid-disabled-bg: var(--palette-neutral-800);
});
