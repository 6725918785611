.Row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1%, 1fr));
    grid-auto-flow: row dense;
    position: relative;
    place-items: center center;
    padding-block-start: 4px;
    margin-block-end: 4px;
    border-top: 1px solid var(--color-neutral-outlined-disabled-border);
}
