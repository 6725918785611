// Typography

.h1 {
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-xl);
    font-size: var(--font-size-xl4);
    line-height: var(--line-height-xs);
    letter-spacing: -0.025em;
    color: var(--color-text-primary);
}

.h2 {
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-xl);
    font-size: var(--font-size-xl3);
    line-height: var(--line-height-xs);
    letter-spacing: -0.025em;
    color: var(--color-text-primary);
}

.h3 {
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-lg);
    font-size: var(--font-size-xl2);
    line-height: var(--line-height-xs);
    letter-spacing: -0.025em;
    color: var(--color-text-primary);
}

.h4 {
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-lg);
    font-size: var(--font-size-xl);
    line-height: var(--line-height-md);
    letter-spacing: -0.025em;
    color: var(--color-text-primary);
}

.title-lg {
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-lg);
    font-size: var(--font-size-lg);
    line-height: var(--line-height-xs);
    color: var(--color-text-primary);
}

.title-md {
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-md);
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    color: var(--color-text-primary);
}

.title-sm {
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-md);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    color: var(--color-text-primary);
}

.body-lg {
    font-family: var(--font-family-body);
    font-size: var(--font-size-lg);
    line-height: var(--line-height-md);
    color: var(--color-text-secondary);
}

.body-md {
    font-family: var(--font-family-body);
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    color: var(--color-text-secondary);
}

.body-sm {
    font-family: var(--font-family-body);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-md);
    color: var(--color-text-tertiary);
}

.body-xs {
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-md);
    font-size: var(--font-size-xs);
    line-height: var(--line-height-md);
    color: var(--color-text-tertiary);
}
