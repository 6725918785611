@import (once) "palette.less";

// Negative colors
.color-scheme-light({
    --color-negative-plain: var(--palette-negative-500);
    --color-negative-plain-hover-bg: var(--palette-negative-100);
    --color-negative-plain-active-bg: var(--palette-negative-200);
    --color-negative-plain-disabled: var(--palette-neutral-400);
    --color-negative-outlined: var(--palette-negative-500);
    --color-negative-outlined-border: var(--palette-negative-300);
    --color-negative-outlined-hover-border: var(--palette-negative-300);
    --color-negative-outlined-hover-bg: var(--palette-negative-100);
    --color-negative-outlined-active-bg: var(--palette-negative-200);
    --color-negative-outlined-disabled: var(--palette-neutral-400);
    --color-negative-outlined-disabled-border: var(--palette-neutral-200);
    --color-negative-soft: var(--palette-negative-700);
    --color-negative-soft-bg: var(--palette-negative-100);
    --color-negative-soft-hover-bg: var(--palette-negative-200);
    --color-negative-soft-active: var(--palette-negative-800);
    --color-negative-soft-active-bg: var(--palette-negative-300);
    --color-negative-soft-disabled: var(--palette-neutral-400);
    --color-negative-soft-disabled-bg: var(--palette-neutral-50);
    --color-negative-solid: var(--palette-common-white);
    --color-negative-solid-bg: var(--palette-negative-500);
    --color-negative-solid-hover-bg: var(--palette-negative-600);
    --color-negative-solid-active-bg: var(--palette-negative-700);
    --color-negative-solid-disabled: var(--palette-neutral-400);
    --color-negative-solid-disabled-bg: var(--palette-neutral-100);
});

.color-scheme-dark({
    --color-negative-plain: var(--palette-negative-300);
    --color-negative-plain-hover-bg: var(--palette-negative-800);
    --color-negative-plain-active-bg: var(--palette-negative-700);
    --color-negative-plain-disabled: var(--palette-neutral-500);
    --color-negative-outlined: var(--palette-negative-200);
    --color-negative-outlined-border: var(--palette-negative-700);
    --color-negative-outlined-hover-bg: var(--palette-negative-800);
    --color-negative-outlined-active-bg: var(--palette-negative-700);
    --color-negative-outlined-disabled: var(--palette-neutral-500);
    --color-negative-outlined-disabled-border: var(--palette-neutral-800);
    --color-negative-soft: var(--palette-negative-200);
    --color-negative-soft-bg: var(--palette-negative-800);
    --color-negative-soft-hover-bg: var(--palette-negative-700);
    --color-negative-soft-active: var(--palette-negative-100);
    --color-negative-soft-active-bg: var(--palette-negative-600);
    --color-negative-soft-disabled: var(--palette-neutral-500);
    --color-negative-soft-disabled-bg: var(--palette-neutral-800);
    --color-negative-solid: var(--palette-common-white);
    --color-negative-solid-bg: var(--palette-negative-500);
    --color-negative-solid-hover-bg: var(--palette-negative-600);
    --color-negative-solid-active-bg: var(--palette-negative-700);
    --color-negative-solid-disabled: var(--palette-neutral-500);
    --color-negative-solid-disabled-bg: var(--palette-neutral-800);
});
