svg {
    &[scale="small"] {
        width: 0.8em;
        height: 0.8em;
    }

    &[scale="medium"] {
        width: 1em;
        height: 1em;
    }

    &[scale="large"] {
        width: 1.2em;
        height: 1.2em;
    }
}
