@import (once) "palette.less";

// Positive colors
.color-scheme-light({
    --color-positive-plain: var(--palette-positive-500);
    --color-positive-plain-hover-bg: var(--palette-positive-100);
    --color-positive-plain-active-bg: var(--palette-positive-200);
    --color-positive-plain-disabled: var(--palette-neutral-400);
    --color-positive-outlined: var(--palette-positive-500);
    --color-positive-outlined-border: var(--palette-positive-300);
    --color-positive-outlined-hover-bg: var(--palette-positive-100);
    --color-positive-outlined-hover-border: var(--palette-positive-300);
    --color-positive-outlined-active-bg: var(--palette-positive-200);
    --color-positive-outlined-disabled: var(--palette-neutral-400);
    --color-positive-outlined-disabled-border: var(--palette-neutral-200);
    --color-positive-soft: var(--palette-positive-700);
    --color-positive-soft-bg: var(--palette-positive-100);
    --color-positive-soft-hover-bg: var(--palette-positive-200);
    --color-positive-soft-active: var(--palette-positive-800);
    --color-positive-soft-active-bg: var(--palette-positive-300);
    --color-positive-soft-disabled: var(--palette-neutral-400);
    --color-positive-soft-disabled-bg: var(--palette-neutral-50);
    --color-positive-solid: var(--palette-common-white);
    --color-positive-solid-bg: var(--palette-positive-500);
    --color-positive-solid-hover-bg: var(--palette-positive-600);
    --color-positive-solid-active-bg: var(--palette-positive-700);
    --color-positive-solid-disabled: var(--palette-neutral-400);
    --color-positive-solid-disabled-bg: var(--palette-neutral-100);
});

.color-scheme-dark({
    --color-positive-plain: var(--palette-positive-300);
    --color-positive-plain-hover-bg: var(--palette-positive-800);
    --color-positive-plain-active-bg: var(--palette-positive-700);
    --color-positive-plain-disabled: var(--palette-neutral-500);
    --color-positive-outlined: var(--palette-positive-200);
    --color-positive-outlined-border: var(--palette-positive-700);
    --color-positive-outlined-hover-bg: var(--palette-positive-800);
    --color-positive-outlined-active-bg: var(--palette-positive-700);
    --color-positive-outlined-disabled: var(--palette-neutral-500);
    --color-positive-outlined-disabled-border: var(--palette-neutral-800);
    --color-positive-soft: var(--palette-positive-200);
    --color-positive-soft-bg: var(--palette-positive-800);
    --color-positive-soft-hover-bg: var(--palette-positive-700);
    --color-positive-soft-active: var(--palette-positive-100);
    --color-positive-soft-active-bg: var(--palette-positive-600);
    --color-positive-soft-disabled: var(--palette-neutral-500);
    --color-positive-soft-disabled-bg: var(--palette-neutral-800);
    --color-positive-solid: var(--palette-common-white);
    --color-positive-solid-bg: var(--palette-positive-500);
    --color-positive-solid-hover-bg: var(--palette-positive-600);
    --color-positive-solid-active-bg: var(--palette-positive-700);
    --color-positive-solid-disabled: var(--palette-neutral-500);
    --color-positive-solid-disabled-bg: var(--palette-neutral-800);
});
