// Z-Index
:root {
    --z-index-badge: 1;
    --z-index-table: 10;
    --z-index-backdrop: 1000;
    --z-index-popup: 1000;
    --z-index-modal: 1300;
    --z-index-snackbar: 1400;
    --z-index-tooltip: 1500;
    --z-index-sticky: 10000;
}
