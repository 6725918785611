// Font weights
:root {
    --font-weight-xs2: 100;
    --font-weight-xs: 200;
    --font-weight-sm: 300;
    --font-weight-md: 400;
    --font-weight-lg: 500;
    --font-weight-xl: 600;
    --font-weight-xl2: 700;
    --font-weight-xl3: 800;
    --font-weight-xl4: 900;
}
