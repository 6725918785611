.ValueInput {
    position: relative;
    padding: 0.25rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-inline-end: 1rem;
        justify-self: center;
        font-size: var(--font-size-xl2);
        font-weight: var(--font-weight-sm);

        small {
            padding-inline-start: 0.2rem;
            font-size: var(--font-size-xs);
            font-weight: var(--font-weight-lg);
        }
    }

    .value {
        flex: 0 0 auto;
        width: 12rem;
    }

    input {
        text-align: center;
    }

    .diff {
        position: absolute;
        display: flex;
        justify-content: center;
        inset-inline-end: 4em;
        inset-block-start: -2px;
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-lg);
        padding-inline: 0.5em;
        margin-inline: -0.5em;
        border: 1px solid;
        border-radius: var(--radius-lg);

        &.positive {
            color: var(--color-positive-outlined);
            background-color: var(--color-positive-outlined-hover-bg);
            border-color: var(--color-positive-outlined-hover-border);

            &::before {
                content: "+";
            }
        }

        &:not(.positive) {
            color: var(--color-negative-outlined);
            background-color: var(--color-negative-outlined-hover-bg);
            border-color: var(--color-negative-outlined-hover-border);

            &::before {
                content: "–";
            }
        }
    }
}
