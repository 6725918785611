.Backdrop {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-index-backdrop);
}

.Dropdown {
    position: absolute;
    width: fit-content;
    height: fit-content;
    overflow: auto;
    color: var(--color-text-primary);
    background-color: var(--color-background-popup);
    border-radius: var(--radius-sm);
    box-shadow: var(--shadow-md);
    display: flex;
    flex-direction: column;
    padding: 0;

    &:not(.hover) {
        margin-block-start: 2px;
    }
}
