@import (once) "palette.less";

// Background colors
.color-scheme-light({
    --color-background-body: var(--palette-common-white);
    --color-background-surface: var(--palette-neutral-50);
    --color-background-popup: var(--palette-common-white);
    --color-background-level1: var(--palette-neutral-100);
    --color-background-level2: var(--palette-neutral-200);
    --color-background-level3: var(--palette-neutral-300);
    --color-background-tooltip: var(--palette-neutral-500);
    --color-background-backdrop: color-mix(in srgb, var(--palette-neutral-800) 25%, transparent);
});

.color-scheme-dark({
    --color-background-body: var(--palette-common-black);
    --color-background-surface: var(--palette-neutral-900);
    --color-background-popup: var(--palette-common-black);
    --color-background-level1: var(--palette-neutral-800);
    --color-background-level2: var(--palette-neutral-700);
    --color-background-level3: var(--palette-neutral-600);
    --color-background-tooltip: var(--palette-neutral-600);
    --color-background-backdrop: color-mix(in srgb, var(--palette-neutral-800) 25%, transparent);
});
