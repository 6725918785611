// Font styles
:root {
    --focus-thickness: 2px;
    --line-height-sm: 1.25;
    --line-height-md: 1.5;
    --line-height-lg: 1.7;
    --letter-spacing-xs: -0.04em;
    --letter-spacing-sm: -0.01em;
    --letter-spacing-md: 0.083em;
    --letter-spacing-lg: 0.125em;
}
