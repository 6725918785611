:root {
    // Common colors
    --palette-common-white: #fff;
    --palette-common-black: #09090d;

    // Neutral colors
    --palette-neutral-50: #f7f7f8;
    --palette-neutral-100: #ebebef;
    --palette-neutral-200: #d8d8df;
    --palette-neutral-300: #b9b9c6;
    --palette-neutral-400: #8f8fa3;
    --palette-neutral-500: #73738c;
    --palette-neutral-600: #5a5a72;
    --palette-neutral-700: #434356;
    --palette-neutral-800: #25252d;
    --palette-neutral-900: #131318;

    // Primary colors
    --palette-primary-50: #f4faff;
    --palette-primary-100: #ddf1ff;
    --palette-primary-200: #addbff;
    --palette-primary-300: #6fb6ff;
    --palette-primary-400: #3990ff;
    --palette-primary-500: #096bde;
    --palette-primary-600: #054da7;
    --palette-primary-700: #02367d;
    --palette-primary-800: #072859;
    --palette-primary-900: #00153c;

    // Secondary colors
    --palette-secondary-50: #fdf7ff;
    --palette-secondary-100: #f4eaff;
    --palette-secondary-200: #e1cbff;
    --palette-secondary-300: #c69eff;
    --palette-secondary-400: #a374f9;
    --palette-secondary-500: #814dde;
    --palette-secondary-600: #5f35ae;
    --palette-secondary-700: #452382;
    --palette-secondary-800: #301761;
    --palette-secondary-900: #1d0a42;

    // Success colors
    --palette-positive-50: #f3fef5;
    --palette-positive-100: #d7f5dd;
    --palette-positive-200: #77ec95;
    --palette-positive-300: #4cc76e;
    --palette-positive-400: #2ca24d;
    --palette-positive-500: #1a7d36;
    --palette-positive-600: #0f5d26;
    --palette-positive-700: #034318;
    --palette-positive-800: #002f0f;
    --palette-positive-900: #001d09;

    // Warning colors
    --palette-warning-50: #fff8c5;
    --palette-warning-100: #fae17d;
    --palette-warning-200: #eac54f;
    --palette-warning-300: #d4a72c;
    --palette-warning-400: #bf8700;
    --palette-warning-500: #9a6700;
    --palette-warning-600: #7d4e00;
    --palette-warning-700: #633c01;
    --palette-warning-800: #4d2d00;
    --palette-warning-900: #3b2300;

    // Failure colors
    --palette-negative-50: #fff8f6;
    --palette-negative-100: #ffe9e8;
    --palette-negative-200: #ffc7c5;
    --palette-negative-300: #ff9192;
    --palette-negative-400: #fa5255;
    --palette-negative-500: #d3232f;
    --palette-negative-600: #a10e25;
    --palette-negative-700: #77061b;
    --palette-negative-800: #580013;
    --palette-negative-900: #39000d;
}

//noinspection Stylelint
.color-scheme-light(@ruleset) {
    :root {
        &[data-color-scheme="light"] {
            @ruleset();
        }

        &:not([data-color-scheme]) {
            @media (prefers-color-scheme: light) {
                @ruleset();
            }
        }
    }
}

//noinspection Stylelint
.color-scheme-dark(@ruleset) {
    :root {
        &[data-color-scheme="dark"] {
            @ruleset();
        }

        &:not([data-color-scheme]) {
            @media (prefers-color-scheme: dark) {
                @ruleset();
            }
        }
    }
}
