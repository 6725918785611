// Border radius
:root {
    --radius-xs: 2px;
    //--radius-xs: 4px;
    --radius-sm: 6px;
    //--radius-sm: 8px;
    --radius-md: 8px;
    //--radius-md: 12px;
    --radius-lg: 12px;
    //--radius-lg: 16px;
    --radius-xl: 16px;
    //--radius-xl: 20px;
}
