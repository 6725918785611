.Avatar {
    width: 32px;
    height: 32px;
    overflow: hidden;

    .picture {
        width: 32px;
        height: 32px;
        border-radius: 50%;

        &::part(alttext) {
            display: none;
        }
    }

    .letters {
        width: 32px;
        height: 32px;
        line-height: 32px;
        background: var(--color-neutral-soft-bg);
        border-radius: 50%;
        overflow: hidden;
        font-size: var(--font-size-sm);
    }
}
