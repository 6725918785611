.ValueBox {
    &.fullScreen {
        min-width: 100vw;
        min-height: 100vh;
        max-width: 100vw;
        max-height: 100vh;
    }

    header {
        flex-wrap: nowrap;

        .title {
            flex: 1 0 auto;
            padding: 0 1.75rem;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;

            time {
                font-size: var(--font-size-xl3);
                font-weight: var(--font-weight-lg);
            }

            .controls {
                margin-block-start: 0.5rem;
            }
        }

        .close {
            flex: 0;
            width: 0;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-end;
        }
    }

    @media (width <= 320px) {
        article {
            padding-inline: 0;
        }
    }

    @media (width >= 640px) {
        max-width: 100vw;
        max-height: 100vh;

        article {
            columns: 2;
        }
    }

    @media (width >= 768px) {
        article {
            column-gap: 8vw;
        }
    }
}
