.Row {
    border-top: 1px solid var(--color-neutral-outlined-disabled-border);
    padding-block-start: 4px;
    margin-block-end: 4px;
}

.name {
    justify-self: stretch;
    font-weight: var(--font-weight-xl);

    &.unavailable {
        color: var(--color-neutral-outlined-disabled);
        text-decoration: line-through;
    }

    &:not(.unavailable).removing {
        color: var(--color-negative-outlined);
        text-decoration: line-through;
    }
}
