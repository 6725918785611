@import (once) "@ui/theme.less";
@import (once) "@ui/svg.less";

:root {
    --toolbar-height: 48px;
}

html {
    //min-height: calc(100% + env(safe-area-inset-top));
    //padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    color: var(--color-text-primary);
    background: var(--color-background-body);
    user-select: none;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: var(--font-family-body);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: var(--font-family-code);
}
