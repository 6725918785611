
.Button {
    &.variant-solid {
        &.color-neutral {
            --button-variant-color: var(--color-neutral-solid);
            --button-variant-bg: var(--color-neutral-solid-bg);
            --button-variant-active-bg: var(--color-neutral-solid-active-bg);
            --button-variant-hover-bg: var(--color-neutral-solid-hover-bg);
            --button-variant-disabled: var(--color-neutral-solid-disabled);
            --button-variant-disabled-bg: var(--color-neutral-solid-disabled-bg);
        }

        &.color-primary {
            --button-variant-color: var(--color-primary-solid);
            --button-variant-bg: var(--color-primary-solid-bg);
            --button-variant-active-bg: var(--color-primary-solid-active-bg);
            --button-variant-hover-bg: var(--color-primary-solid-hover-bg);
            --button-variant-disabled: var(--color-primary-solid-disabled);
            --button-variant-disabled-bg: var(--color-primary-solid-disabled-bg);
        }

        &.color-secondary {
            --button-variant-color: var(--color-secondary-solid);
            --button-variant-bg: var(--color-secondary-solid-bg);
            --button-variant-active-bg: var(--color-secondary-solid-active-bg);
            --button-variant-hover-bg: var(--color-secondary-solid-hover-bg);
            --button-variant-disabled: var(--color-secondary-solid-disabled);
            --button-variant-disabled-bg: var(--color-secondary-solid-disabled-bg);
        }

        &.color-positive {
            --button-variant-color: var(--color-positive-solid);
            --button-variant-bg: var(--color-positive-solid-bg);
            --button-variant-active-bg: var(--color-positive-solid-active-bg);
            --button-variant-hover-bg: var(--color-positive-solid-hover-bg);
            --button-variant-disabled: var(--color-positive-solid-disabled);
            --button-variant-disabled-bg: var(--color-positive-solid-disabled-bg);
        }

        &.color-warning {
            --button-variant-color: var(--color-warning-solid);
            --button-variant-bg: var(--color-warning-solid-bg);
            --button-variant-active-bg: var(--color-warning-solid-active-bg);
            --button-variant-hover-bg: var(--color-warning-solid-hover-bg);
            --button-variant-disabled: var(--color-warning-solid-disabled);
            --button-variant-disabled-bg: var(--color-warning-solid-disabled-bg);
        }

        &.color-negative {
            --button-variant-color: var(--color-negative-solid);
            --button-variant-bg: var(--color-negative-solid-bg);
            --button-variant-active-bg: var(--color-negative-solid-active-bg);
            --button-variant-hover-bg: var(--color-negative-solid-hover-bg);
            --button-variant-disabled: var(--color-negative-solid-disabled);
            --button-variant-disabled-bg: var(--color-negative-solid-disabled-bg);
        }
    }

    &.variant-soft {
        &.color-neutral {
            --button-variant-color: var(--color-neutral-soft);
            --button-variant-bg: var(--color-neutral-soft-bg);
            --button-variant-active-bg: var(--color-neutral-soft-active-bg);
            --button-variant-hover: var(--color-neutral-soft-hover);
            --button-variant-hover-bg: var(--color-neutral-soft-hover-bg);
            --button-variant-disabled: var(--color-neutral-soft-disabled);
            --button-variant-disabled-bg: var(--color-neutral-soft-disabled-bg);
        }

        &.color-primary {
            --button-variant-color: var(--color-primary-soft);
            --button-variant-bg: var(--color-primary-soft-bg);
            --button-variant-active-bg: var(--color-primary-soft-active-bg);
            --button-variant-hover-bg: var(--color-primary-soft-hover-bg);
            --button-variant-disabled: var(--color-primary-soft-disabled);
            --button-variant-disabled-bg: var(--color-primary-soft-disabled-bg);
        }

        &.color-secondary {
            --button-variant-color: var(--color-secondary-soft);
            --button-variant-bg: var(--color-secondary-soft-bg);
            --button-variant-active-bg: var(--color-secondary-soft-active-bg);
            --button-variant-hover-bg: var(--color-secondary-soft-hover-bg);
            --button-variant-disabled: var(--color-secondary-soft-disabled);
            --button-variant-disabled-bg: var(--color-secondary-soft-disabled-bg);
        }

        &.color-positive {
            --button-variant-color: var(--color-positive-soft);
            --button-variant-bg: var(--color-positive-soft-bg);
            --button-variant-active-bg: var(--color-positive-soft-active-bg);
            --button-variant-hover-bg: var(--color-positive-soft-hover-bg);
            --button-variant-disabled: var(--color-positive-soft-disabled);
            --button-variant-disabled-bg: var(--color-positive-soft-disabled-bg);
        }

        &.color-warning {
            --button-variant-color: var(--color-warning-soft);
            --button-variant-bg: var(--color-warning-soft-bg);
            --button-variant-active-bg: var(--color-warning-soft-active-bg);
            --button-variant-hover-bg: var(--color-warning-soft-hover-bg);
            --button-variant-disabled: var(--color-warning-soft-disabled);
            --button-variant-disabled-bg: var(--color-warning-soft-disabled-bg);
        }

        &.color-negative {
            --button-variant-color: var(--color-negative-soft);
            --button-variant-bg: var(--color-negative-soft-bg);
            --button-variant-active-bg: var(--color-negative-soft-active-bg);
            --button-variant-hover-bg: var(--color-negative-soft-hover-bg);
            --button-variant-disabled: var(--color-negative-soft-disabled);
            --button-variant-disabled-bg: var(--color-negative-soft-disabled-bg);
        }
    }

    &.variant-outlined {
        &.color-neutral {
            --button-variant-color: var(--color-neutral-outlined);
            --button-variant-border: var(--color-neutral-outlined-border);
            --button-variant-active-bg: var(--color-neutral-outlined-active-bg);
            --button-variant-hover: var(--color-neutral-outlined-hover);
            --button-variant-hover-bg: var(--color-neutral-outlined-hover-bg);
            --button-variant-hover-border: var(--color-neutral-outlined-hover-border);
            --button-variant-disabled: var(--color-neutral-outlined-disabled);
            --button-variant-disabled-border: var(--color-neutral-outlined-disabled-border);
        }

        &.color-primary {
            --button-variant-color: var(--color-primary-outlined);
            --button-variant-border: var(--color-primary-outlined-border);
            --button-variant-active-bg: var(--color-primary-outlined-active-bg);
            --button-variant-hover-bg: var(--color-primary-outlined-hover-bg);
            --button-variant-hover-border: var(--color-primary-outlined-hover-border);
            --button-variant-disabled: var(--color-primary-outlined-disabled);
            --button-variant-disabled-border: var(--color-primary-outlined-disabled-border);
        }

        &.color-secondary {
            --button-variant-color: var(--color-secondary-outlined);
            --button-variant-border: var(--color-secondary-outlined-border);
            --button-variant-active-bg: var(--color-secondary-outlined-active-bg);
            --button-variant-hover-bg: var(--color-secondary-outlined-hover-bg);
            --button-variant-hover-border: var(--color-secondary-outlined-hover-border);
            --button-variant-disabled: var(--color-secondary-outlined-disabled);
            --button-variant-disabled-border: var(--color-secondary-outlined-disabled-border);
        }

        &.color-positive {
            --button-variant-color: var(--color-positive-outlined);
            --button-variant-border: var(--color-positive-outlined-border);
            --button-variant-active-bg: var(--color-positive-outlined-active-bg);
            --button-variant-hover-bg: var(--color-positive-outlined-hover-bg);
            --button-variant-hover-border: var(--color-positive-outlined-hover-border);
            --button-variant-disabled: var(--color-positive-outlined-disabled);
            --button-variant-disabled-border: var(--color-positive-outlined-disabled-border);
        }

        &.color-warning {
            --button-variant-color: var(--color-warning-outlined);
            --button-variant-border: var(--color-warning-outlined-border);
            --button-variant-active-bg: var(--color-warning-outlined-active-bg);
            --button-variant-hover-bg: var(--color-warning-outlined-hover-bg);
            --button-variant-hover-border: var(--color-warning-outlined-hover-border);
            --button-variant-disabled: var(--color-warning-outlined-disabled);
            --button-variant-disabled-border: var(--color-warning-outlined-disabled-border);
        }

        &.color-negative {
            --button-variant-color: var(--color-negative-outlined);
            --button-variant-border: var(--color-negative-outlined-border);
            --button-variant-active-bg: var(--color-negative-outlined-active-bg);
            --button-variant-hover-bg: var(--color-negative-outlined-hover-bg);
            --button-variant-hover-border: var(--color-negative-outlined-hover-border);
            --button-variant-disabled: var(--color-negative-outlined-disabled);
            --button-variant-disabled-border: var(--color-negative-outlined-disabled-border);
        }
    }

    &.variant-plain {
        &.color-neutral {
            --button-variant-color: var(--color-neutral-plain);
            --button-variant-active-bg: var(--color-neutral-plain-active-bg);
            --button-variant-hover: var(--color-neutral-plain-hover);
            --button-variant-hover-bg: var(--color-neutral-plain-hover-bg);
            --button-variant-disabled: var(--color-neutral-plain-disabled);
        }

        &.color-primary {
            --button-variant-color: var(--color-primary-plain);
            --button-variant-active-bg: var(--color-primary-plain-active-bg);
            --button-variant-hover-bg: var(--color-primary-plain-hover-bg);
            --button-variant-disabled: var(--color-primary-plain-disabled);
        }

        &.color-secondary {
            --button-variant-color: var(--color-secondary-plain);
            --button-variant-active-bg: var(--color-secondary-plain-active-bg);
            --button-variant-hover-bg: var(--color-secondary-plain-hover-bg);
            --button-variant-disabled: var(--color-secondary-plain-disabled);
        }

        &.color-positive {
            --button-variant-color: var(--color-positive-plain);
            --button-variant-active-bg: var(--color-positive-plain-active-bg);
            --button-variant-hover-bg: var(--color-positive-plain-hover-bg);
            --button-variant-disabled: var(--color-positive-plain-disabled);
        }

        &.color-warning {
            --button-variant-color: var(--color-warning-plain);
            --button-variant-active-bg: var(--color-warning-plain-active-bg);
            --button-variant-hover-bg: var(--color-warning-plain-hover-bg);
            --button-variant-disabled: var(--color-warning-plain-disabled);
        }

        &.color-negative {
            --button-variant-color: var(--color-negative-plain);
            --button-variant-active-bg: var(--color-negative-plain-active-bg);
            --button-variant-hover-bg: var(--color-negative-plain-hover-bg);
            --button-variant-disabled: var(--color-negative-plain-disabled);
        }
    }

    --button-color: var(--button-variant-color);
    --button-bg: var(--button-variant-bg, transparent);
    --button-border: var(--button-variant-border, var(--button-bg));
    --button-hover: var(--button-variant-hover, var(--button-color));
    --button-hover-bg: var(--button-variant-hover-bg, var(--button-bg));
    --button-hover-border: var(--button-variant-hover-border, var(--button-border));
    --button-active: var(--button-variant-color);
    --button-active-bg: var(--button-variant-active-bg, var(--button-bg));
    --button-active-border: var(--button-variant-active-bg, var(--button-border));
    --button-disabled: var(--button-variant-disabled, var(--button-color));
    --button-disabled-bg: var(--button-variant-disabled-bg, var(--button-bg));
    --button-disabled-border: var(--button-variant-disabled-bg, var(--button-border));

    &.size-small {
        --button-icon-size: var(--font-size-md);
        --button-font-size: var(--font-size-sm);
        --button-min-size: 2rem;
        --button-padding-block: 0.125rem;
        --button-padding-inline: 0.75rem;
        --button-radius: var(--radius-sm);
    }

    &.size-medium {
        --button-icon-size: var(--font-size-xl);
        --button-font-size: var(--font-size-md);
        --button-min-size: 2.5rem;
        --button-padding-block: 0.25rem;
        --button-padding-inline: 1rem;
        --button-radius: var(--radius-md);
    }

    &.size-large {
        --button-icon-size: var(--font-size-xl2);
        --button-font-size: var(--font-size-lg);
        --button-min-size: 3rem;
        --button-padding-block: 0.375rem;
        --button-padding-inline: 1.5rem;
        --button-radius: var(--radius-lg);
    }

    &.spacing-none {
        --button-min-size: calc(var(--button-icon-size) + 2px);
        --button-spacing: 0;
        --button-radius: var(--radius-xs);
    }

    &.spacing-half {
        --button-spacing: 0.75;
    }

    &.spacing-small {
        --button-spacing: 1;
    }

    &.spacing-medium {
        --button-spacing: 1.25;
    }

    &.spacing-large {
        --button-spacing: 1.5;
    }

    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    min-width: calc(var(--button-min-size) * var(--button-spacing, 1));
    min-height: calc(var(--button-min-size) * var(--button-spacing, 1));
    padding-block: calc(var(--button-padding-block) * var(--button-spacing, 1));
    padding-inline: calc(var(--button-padding-inline) * var(--button-spacing, 1));
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-lg);
    font-size: var(--button-font-size);
    line-height: 1;
    text-decoration: none;
    color: var(--button-color);
    background-color: var(--button-bg);
    border: 1px solid var(--button-border);
    border-radius: var(--button-radius);

    &:active {
        color: var(--button-active);
        background-color: var(--button-active-bg);
        border-color: var(--button-active-border);
    }

    &:hover,
    &:focus {
        color: var(--button-hover);
        background-color: var(--button-hover-bg);
        border-color: var(--button-hover-border);
    }

    &:disabled {
        color: var(--button-disabled);
        background-color: var(--button-disabled-bg);
        border-color: var(--button-disabled-border);
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    &.full-width {
        width: 100%;
    }

    &.full-height {
        height: 100%;
    }

    svg {
        width: var(--button-icon-size);
        height: var(--button-icon-size);
        color: inherit;
        fill: currentcolor;

        &:first-child:not(:last-child) {
            margin-inline-end: 0.25rem;
        }

        &:last-child:not(:first-child) {
            margin-inline-start: 0.25rem;
        }
    }

    + .Button {
        margin-inline-start: 1px;
    }
}

.ButtonGroup {
    .Button {
        + .Button {
            border-start-start-radius: 0;
            border-end-start-radius: 0;
        }

        &:has(+ .Button) {
            border-start-end-radius: 0;
            border-end-end-radius: 0;
        }
    }
}
