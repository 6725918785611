.Table {
    display: grid;
    grid-template-rows: 1fr;
}

.Head {
    position: relative;
    background-color: var(--color-background-body);
    z-index: 1;
    box-shadow: var(--shadow-sm);
}

.Body {
    position: relative;
    overflow-y: auto;
}
