.App {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.center {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .error {
        display: flex;
        align-items: center;
        color: var(--color-negative-plain);
        fill: currentcolor;
    }
}
