
.Checkbox {
    &.variant-solid {
        &.color-neutral {
            --checkbox-variant-color: var(--color-neutral-solid);
            --checkbox-variant-bg: var(--color-neutral-solid-bg);
            --checkbox-variant-active-bg: var(--color-neutral-solid-active-bg);
            --checkbox-variant-hover-bg: var(--color-neutral-solid-hover-bg);
            --checkbox-variant-disabled: var(--color-neutral-solid-disabled);
            --checkbox-variant-disabled-bg: var(--color-neutral-solid-disabled-bg);
        }

        &.color-primary {
            --checkbox-variant-color: var(--color-primary-solid);
            --checkbox-variant-bg: var(--color-primary-solid-bg);
            --checkbox-variant-active-bg: var(--color-primary-solid-active-bg);
            --checkbox-variant-hover-bg: var(--color-primary-solid-hover-bg);
            --checkbox-variant-disabled: var(--color-primary-solid-disabled);
            --checkbox-variant-disabled-bg: var(--color-primary-solid-disabled-bg);
        }

        &.color-secondary {
            --checkbox-variant-color: var(--color-secondary-solid);
            --checkbox-variant-bg: var(--color-secondary-solid-bg);
            --checkbox-variant-active-bg: var(--color-secondary-solid-active-bg);
            --checkbox-variant-hover-bg: var(--color-secondary-solid-hover-bg);
            --checkbox-variant-disabled: var(--color-secondary-solid-disabled);
            --checkbox-variant-disabled-bg: var(--color-secondary-solid-disabled-bg);
        }

        &.color-positive {
            --checkbox-variant-color: var(--color-positive-solid);
            --checkbox-variant-bg: var(--color-positive-solid-bg);
            --checkbox-variant-active-bg: var(--color-positive-solid-active-bg);
            --checkbox-variant-hover-bg: var(--color-positive-solid-hover-bg);
            --checkbox-variant-disabled: var(--color-positive-solid-disabled);
            --checkbox-variant-disabled-bg: var(--color-positive-solid-disabled-bg);
        }

        &.color-warning {
            --checkbox-variant-color: var(--color-warning-solid);
            --checkbox-variant-bg: var(--color-warning-solid-bg);
            --checkbox-variant-active-bg: var(--color-warning-solid-active-bg);
            --checkbox-variant-hover-bg: var(--color-warning-solid-hover-bg);
            --checkbox-variant-disabled: var(--color-warning-solid-disabled);
            --checkbox-variant-disabled-bg: var(--color-warning-solid-disabled-bg);
        }

        &.color-negative {
            --checkbox-variant-color: var(--color-negative-solid);
            --checkbox-variant-bg: var(--color-negative-solid-bg);
            --checkbox-variant-active-bg: var(--color-negative-solid-active-bg);
            --checkbox-variant-hover-bg: var(--color-negative-solid-hover-bg);
            --checkbox-variant-disabled: var(--color-negative-solid-disabled);
            --checkbox-variant-disabled-bg: var(--color-negative-solid-disabled-bg);
        }
    }

    &.variant-soft {
        &.color-neutral {
            --checkbox-variant-color: var(--color-neutral-soft);
            --checkbox-variant-bg: var(--color-neutral-soft-bg);
            --checkbox-variant-active-bg: var(--color-neutral-soft-active-bg);
            --checkbox-variant-hover: var(--color-neutral-soft-hover);
            --checkbox-variant-hover-bg: var(--color-neutral-soft-hover-bg);
            --checkbox-variant-disabled: var(--color-neutral-soft-disabled);
            --checkbox-variant-disabled-bg: var(--color-neutral-soft-disabled-bg);
        }

        &.color-primary {
            --checkbox-variant-color: var(--color-primary-soft);
            --checkbox-variant-bg: var(--color-primary-soft-bg);
            --checkbox-variant-active-bg: var(--color-primary-soft-active-bg);
            --checkbox-variant-hover-bg: var(--color-primary-soft-hover-bg);
            --checkbox-variant-disabled: var(--color-primary-soft-disabled);
            --checkbox-variant-disabled-bg: var(--color-primary-soft-disabled-bg);
        }

        &.color-secondary {
            --checkbox-variant-color: var(--color-secondary-soft);
            --checkbox-variant-bg: var(--color-secondary-soft-bg);
            --checkbox-variant-active-bg: var(--color-secondary-soft-active-bg);
            --checkbox-variant-hover-bg: var(--color-secondary-soft-hover-bg);
            --checkbox-variant-disabled: var(--color-secondary-soft-disabled);
            --checkbox-variant-disabled-bg: var(--color-secondary-soft-disabled-bg);
        }

        &.color-positive {
            --checkbox-variant-color: var(--color-positive-soft);
            --checkbox-variant-bg: var(--color-positive-soft-bg);
            --checkbox-variant-active-bg: var(--color-positive-soft-active-bg);
            --checkbox-variant-hover-bg: var(--color-positive-soft-hover-bg);
            --checkbox-variant-disabled: var(--color-positive-soft-disabled);
            --checkbox-variant-disabled-bg: var(--color-positive-soft-disabled-bg);
        }

        &.color-warning {
            --checkbox-variant-color: var(--color-warning-soft);
            --checkbox-variant-bg: var(--color-warning-soft-bg);
            --checkbox-variant-active-bg: var(--color-warning-soft-active-bg);
            --checkbox-variant-hover-bg: var(--color-warning-soft-hover-bg);
            --checkbox-variant-disabled: var(--color-warning-soft-disabled);
            --checkbox-variant-disabled-bg: var(--color-warning-soft-disabled-bg);
        }

        &.color-negative {
            --checkbox-variant-color: var(--color-negative-soft);
            --checkbox-variant-bg: var(--color-negative-soft-bg);
            --checkbox-variant-active-bg: var(--color-negative-soft-active-bg);
            --checkbox-variant-hover-bg: var(--color-negative-soft-hover-bg);
            --checkbox-variant-disabled: var(--color-negative-soft-disabled);
            --checkbox-variant-disabled-bg: var(--color-negative-soft-disabled-bg);
        }
    }

    &.variant-outlined {
        &.color-neutral {
            --checkbox-variant-color: var(--color-neutral-outlined);
            --checkbox-variant-border: var(--color-neutral-outlined-border);
            --checkbox-variant-active-bg: var(--color-neutral-outlined-active-bg);
            --checkbox-variant-hover: var(--color-neutral-outlined-hover);
            --checkbox-variant-hover-bg: var(--color-neutral-outlined-hover-bg);
            --checkbox-variant-hover-border: var(--color-neutral-outlined-hover-border);
            --checkbox-variant-disabled: var(--color-neutral-outlined-disabled);
            --checkbox-variant-disabled-border: var(--color-neutral-outlined-disabled-border);
        }

        &.color-primary {
            --checkbox-variant-color: var(--color-primary-outlined);
            --checkbox-variant-border: var(--color-primary-outlined-border);
            --checkbox-variant-active-bg: var(--color-primary-outlined-active-bg);
            --checkbox-variant-hover-bg: var(--color-primary-outlined-hover-bg);
            --checkbox-variant-hover-border: var(--color-primary-outlined-hover-border);
            --checkbox-variant-disabled: var(--color-primary-outlined-disabled);
            --checkbox-variant-disabled-border: var(--color-primary-outlined-disabled-border);
        }

        &.color-secondary {
            --checkbox-variant-color: var(--color-secondary-outlined);
            --checkbox-variant-border: var(--color-secondary-outlined-border);
            --checkbox-variant-active-bg: var(--color-secondary-outlined-active-bg);
            --checkbox-variant-hover-bg: var(--color-secondary-outlined-hover-bg);
            --checkbox-variant-hover-border: var(--color-secondary-outlined-hover-border);
            --checkbox-variant-disabled: var(--color-secondary-outlined-disabled);
            --checkbox-variant-disabled-border: var(--color-secondary-outlined-disabled-border);
        }

        &.color-positive {
            --checkbox-variant-color: var(--color-positive-outlined);
            --checkbox-variant-border: var(--color-positive-outlined-border);
            --checkbox-variant-active-bg: var(--color-positive-outlined-active-bg);
            --checkbox-variant-hover-bg: var(--color-positive-outlined-hover-bg);
            --checkbox-variant-hover-border: var(--color-positive-outlined-hover-border);
            --checkbox-variant-disabled: var(--color-positive-outlined-disabled);
            --checkbox-variant-disabled-border: var(--color-positive-outlined-disabled-border);
        }

        &.color-warning {
            --checkbox-variant-color: var(--color-warning-outlined);
            --checkbox-variant-border: var(--color-warning-outlined-border);
            --checkbox-variant-active-bg: var(--color-warning-outlined-active-bg);
            --checkbox-variant-hover-bg: var(--color-warning-outlined-hover-bg);
            --checkbox-variant-hover-border: var(--color-warning-outlined-hover-border);
            --checkbox-variant-disabled: var(--color-warning-outlined-disabled);
            --checkbox-variant-disabled-border: var(--color-warning-outlined-disabled-border);
        }

        &.color-negative {
            --checkbox-variant-color: var(--color-negative-outlined);
            --checkbox-variant-border: var(--color-negative-outlined-border);
            --checkbox-variant-active-bg: var(--color-negative-outlined-active-bg);
            --checkbox-variant-hover-bg: var(--color-negative-outlined-hover-bg);
            --checkbox-variant-hover-border: var(--color-negative-outlined-hover-border);
            --checkbox-variant-disabled: var(--color-negative-outlined-disabled);
            --checkbox-variant-disabled-border: var(--color-negative-outlined-disabled-border);
        }
    }

    &.variant-plain {
        &.color-neutral {
            --checkbox-variant-color: var(--color-neutral-plain);
            --checkbox-variant-active-bg: var(--color-neutral-plain-active-bg);
            --checkbox-variant-hover: var(--color-neutral-plain-hover);
            --checkbox-variant-hover-bg: var(--color-neutral-plain-hover-bg);
            --checkbox-variant-disabled: var(--color-neutral-plain-disabled);
        }

        &.color-primary {
            --checkbox-variant-color: var(--color-primary-plain);
            --checkbox-variant-active-bg: var(--color-primary-plain-active-bg);
            --checkbox-variant-hover-bg: var(--color-primary-plain-hover-bg);
            --checkbox-variant-disabled: var(--color-primary-plain-disabled);
        }

        &.color-secondary {
            --checkbox-variant-color: var(--color-secondary-plain);
            --checkbox-variant-active-bg: var(--color-secondary-plain-active-bg);
            --checkbox-variant-hover-bg: var(--color-secondary-plain-hover-bg);
            --checkbox-variant-disabled: var(--color-secondary-plain-disabled);
        }

        &.color-positive {
            --checkbox-variant-color: var(--color-positive-plain);
            --checkbox-variant-active-bg: var(--color-positive-plain-active-bg);
            --checkbox-variant-hover-bg: var(--color-positive-plain-hover-bg);
            --checkbox-variant-disabled: var(--color-positive-plain-disabled);
        }

        &.color-warning {
            --checkbox-variant-color: var(--color-warning-plain);
            --checkbox-variant-active-bg: var(--color-warning-plain-active-bg);
            --checkbox-variant-hover-bg: var(--color-warning-plain-hover-bg);
            --checkbox-variant-disabled: var(--color-warning-plain-disabled);
        }

        &.color-negative {
            --checkbox-variant-color: var(--color-negative-plain);
            --checkbox-variant-active-bg: var(--color-negative-plain-active-bg);
            --checkbox-variant-hover-bg: var(--color-negative-plain-hover-bg);
            --checkbox-variant-disabled: var(--color-negative-plain-disabled);
        }
    }

    --checkbox-color: var(--checkbox-variant-color);
    --checkbox-bg: var(--checkbox-variant-bg, transparent);
    --checkbox-border: var(--checkbox-variant-border, var(--checkbox-bg));
    --checkbox-hover: var(--checkbox-variant-hover, var(--checkbox-color));
    --checkbox-hover-bg: var(--checkbox-variant-hover-bg, var(--checkbox-bg));
    --checkbox-hover-border: var(--checkbox-variant-hover-border, var(--checkbox-border));
    --checkbox-active: var(--checkbox-variant-color);
    --checkbox-active-bg: var(--checkbox-variant-active-bg, var(--checkbox-bg));
    --checkbox-active-border: var(--checkbox-variant-active-bg, var(--checkbox-border));
    --checkbox-disabled: var(--checkbox-variant-disabled, var(--checkbox-color));
    --checkbox-disabled-bg: var(--checkbox-variant-disabled-bg, var(--checkbox-bg));
    --checkbox-disabled-border: var(--checkbox-variant-disabled-bg, var(--checkbox-border));

    &.size-small {
        --checkbox-icon-size: var(--font-size-lg);
        --checkbox-font-size: var(--font-size-sm);
    }

    &.size-medium {
        --checkbox-icon-size: var(--font-size-xl);
        --checkbox-font-size: var(--font-size-md);
    }

    &.size-large {
        --checkbox-icon-size: var(--font-size-xl2);
        --checkbox-font-size: var(--font-size-lg);
    }

    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    input {
        display: none;
    }

    .label {
        font-size: var(--checkbox-font-size);
        font-family: var(--font-family-body);
        font-weight: var(--font-weight-md);
        line-height: 1;
        text-decoration: none;
        margin-inline-start: 0.35rem;

        &:empty {
            display: none;
        }
    }

    .checkbox {
        width: var(--checkbox-icon-size);
        height: var(--checkbox-icon-size);
        display: inline-flex;
        align-items: center;
        color: var(--checkbox-color);
        background-color: var(--checkbox-bg);
        border: 1px solid var(--checkbox-border);
        border-radius: var(--radius-xs);

        svg {
            user-select: none;
            width: var(--checkbox-icon-size);
            height: var(--checkbox-icon-size);
            color: inherit;
            fill: currentcolor;
        }
    }

    &:active {
        .checkbox {
            color: var(--checkbox-color);
            background-color: var(--checkbox-active-bg);
            border-color: var(--checkbox-active-border);
        }
    }

    &:hover,
    &:focus {
        .checkbox {
            color: var(--checkbox-hover);
            background-color: var(--checkbox-hover-bg);
            border-color: var(--checkbox-hover-border);
        }
    }

    &:has(input:disabled) {
        .label {
            opacity: 0.5;
        }

        .checkbox {
            color: var(--checkbox-disabled);
            background-color: var(--checkbox-disabled-bg);
            border-color: var(--checkbox-disabled-border);
        }
    }

    &:not(:has(input:disabled)) {
        cursor: pointer;
    }

    &:has(input:not(:checked, :indeterminate)) {
        .checkbox svg {
            visibility: hidden;
        }
    }
}
