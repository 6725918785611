.LoginButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > * {
        display: inline-block;
    }

    > * + * {
        margin-inline-start: 4px;
    }
}
