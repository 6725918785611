.ValueCell {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    transition:
        color 0.2s ease-in-out,
        background-color 0.2s ease-in-out;

    &.last {
        color: var(--color-negative-outlined);
    }

    &.empty {
        color: var(--color-neutral-plain-disabled);
    }

    &.removing {
        color: var(--color-negative-solid);
        background-color: var(--color-negative-solid-bg);
        border-radius: var(--radius-xs);
    }

    .value {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-inline: 0.1rem;

        &:has(+ *) {
            &::after {
                content: ",";
            }
        }

        sub {
            font-size: var(--font-size-xs);
        }
    }
}
