// Font sizes
:root {
    --font-size-xs3: 0.5rem;
    --font-size-xs2: 0.625rem;
    --font-size-xs: 0.75rem;
    --font-size-sm: 0.875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.125rem;
    --font-size-xl: 1.25rem;
    --font-size-xl2: 1.5rem;
    --font-size-xl3: 1.875rem;
    --font-size-xl4: 2.25rem;
    --font-size-xl5: 3rem;
    --font-size-xl6: 3.75rem;
    --font-size-xl7: 4.5rem;
}
