@import (once) "palette.less";

// Secondary colors
.color-scheme-light({
    --color-secondary-plain: var(--palette-secondary-500);
    --color-secondary-plain-hover-bg: var(--palette-secondary-100);
    --color-secondary-plain-active-bg: var(--palette-secondary-200);
    --color-secondary-plain-disabled: var(--palette-neutral-400);
    --color-secondary-outlined: var(--palette-secondary-500);
    --color-secondary-outlined-border: var(--palette-secondary-300);
    --color-secondary-outlined-hover-border: var(--palette-secondary-300);
    --color-secondary-outlined-hover-bg: var(--palette-secondary-100);
    --color-secondary-outlined-active-bg: var(--palette-secondary-200);
    --color-secondary-outlined-disabled: var(--palette-neutral-400);
    --color-secondary-outlined-disabled-border: var(--palette-neutral-200);
    --color-secondary-soft: var(--palette-secondary-700);
    --color-secondary-soft-bg: var(--palette-secondary-100);
    --color-secondary-soft-hover-bg: var(--palette-secondary-200);
    --color-secondary-soft-active: var(--palette-secondary-800);
    --color-secondary-soft-active-bg: var(--palette-secondary-300);
    --color-secondary-soft-disabled: var(--palette-neutral-400);
    --color-secondary-soft-disabled-bg: var(--palette-neutral-50);
    --color-secondary-solid: var(--palette-common-white);
    --color-secondary-solid-bg: var(--palette-secondary-500);
    --color-secondary-solid-hover-bg: var(--palette-secondary-600);
    --color-secondary-solid-active-bg: var(--palette-secondary-700);
    --color-secondary-solid-disabled: var(--palette-neutral-400);
    --color-secondary-solid-disabled-bg: var(--palette-neutral-100);
});

.color-scheme-dark({
    --color-secondary-plain: var(--palette-secondary-300);
    --color-secondary-plain-hover-bg: var(--palette-secondary-800);
    --color-secondary-plain-active-bg: var(--palette-secondary-700);
    --color-secondary-plain-disabled: var(--palette-neutral-500);
    --color-secondary-outlined: var(--palette-secondary-200);
    --color-secondary-outlined-border: var(--palette-secondary-700);
    --color-secondary-outlined-hover-bg: var(--palette-secondary-800);
    --color-secondary-outlined-active-bg: var(--palette-secondary-700);
    --color-secondary-outlined-disabled: var(--palette-neutral-500);
    --color-secondary-outlined-disabled-border: var(--palette-neutral-800);
    --color-secondary-soft: var(--palette-secondary-200);
    --color-secondary-soft-bg: var(--palette-secondary-800);
    --color-secondary-soft-hover-bg: var(--palette-secondary-700);
    --color-secondary-soft-active: var(--palette-secondary-100);
    --color-secondary-soft-active-bg: var(--palette-secondary-600);
    --color-secondary-soft-disabled: var(--palette-neutral-500);
    --color-secondary-soft-disabled-bg: var(--palette-neutral-800);
    --color-secondary-solid: var(--palette-common-white);
    --color-secondary-solid-bg: var(--palette-secondary-500);
    --color-secondary-solid-hover-bg: var(--palette-secondary-600);
    --color-secondary-solid-active-bg: var(--palette-secondary-700);
    --color-secondary-solid-disabled: var(--palette-neutral-500);
    --color-secondary-solid-disabled-bg: var(--palette-neutral-800);
});
