@import (once) "palette.less";

// Other colors
:root {
    --color-focus-visible: var(--palette-primary-500);
}

.color-scheme-light({
    --color-selection-bg: color-mix(in srgb, var(--palette-primary-500) 50%, transparent);
    --color-divider: color-mix(in srgb, var(--palette-neutral-500) 20%, transparent);
});

.color-scheme-dark({
    --color-selection-bg: color-mix(in srgb, var(--palette-primary-500) 40%, transparent);
    --color-divider: color-mix(in srgb, var(--palette-neutral-400) 28%, transparent);
});
