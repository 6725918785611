@import (once) "palette.less";

// Text colors
.color-scheme-light({
    --color-text-primary: var(--palette-neutral-800);
    --color-text-secondary: var(--palette-neutral-600);
    --color-text-tertiary: var(--palette-neutral-500);
    --color-text-icon: var(--palette-neutral-500);
});

.color-scheme-dark({
    --color-text-primary: var(--palette-neutral-100);
    --color-text-secondary: var(--palette-neutral-300);
    --color-text-tertiary: var(--palette-neutral-400);
    --color-text-icon: var(--palette-neutral-400);
});
